import jobBoard from "@/services/jobBoard";
import tabFilters from "@/services/tabFilter";
import filter from "@/services/filter";
import router from "@/router/index";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
import job from "@/services/job";

const state = {
  isFetchingJobCandidateSts: false,
  isFetchingJobCandidateStsFilter: false,
  getAllJobsFilterList: [],
  getTabFilterOptions: [],
  getTabSubFilterOptions: [],
  displayStatusSubStatus: [],
  getJobCandidatesByStatusID: [],
  getJobCandidatesByStatusIDCount: 0,
  jobCandidateStatusPagination: {
    limit: 10,
    skip: 0,
    noMoreCandidates: false,
  },
  getJobCandidatesAdvanced: [],
  getJobCandidatesAdvancedCount: 0,
  specialitySubSpeciality: [],
  genericSpecialitySubSpeciality: []
};

const getters = {
  isFetchingJobCandidateSts: (state) => state.isFetchingJobCandidateSts,
  isFetchingJobCandidateStsFilter: (state) =>
    state.isFetchingJobCandidateStsFilter,
  getAllJobsFilterList: (state) =>
    state.getAllJobsFilterList?.length
      ? state.getAllJobsFilterList.map((val) => ({
        // label: `${val.job_id} | ${val.job_title}`,
        label: `${val.display_uid} | ${val.job_title} | ${val.organisation_name}`,
        code: val.job_id,
        candidate_type_id: val?.candidate_type_id,
      }))
      : [],
  getTabFilterOptions: (state) =>
    state.getTabFilterOptions?.length
      ? state.getTabFilterOptions.map((val) => ({
        label: val.filter_?.filter_name,
        code: val.filter_?.id,
      }))
      : [],
  getTabSubFilterOptions: (state) =>
    state.getTabSubFilterOptions?.length
      ? state.getTabSubFilterOptions.map((val) => ({
        label: val.sub_filter_?.filter_name,
        code: val.sub_filter_?.id,
      }))
      : [],
  displayStatusSubStatus: (state) => state.displayStatusSubStatus,
  getJobCandidatesByStatusID: (state) => state.getJobCandidatesByStatusID,
  getJobCandidatesByStatusIDCount: (state) =>
    state.getJobCandidatesByStatusIDCount,
  getJobCandidateStatusPagination: (state) =>
    state.jobCandidateStatusPagination,
  getJobCandidatesAdvanced: (state) => state.getJobCandidatesAdvanced,
  getJobCandidatesAdvancedCount: (state) => state.getJobCandidatesAdvancedCount,
  specialitySubSpeciality: (state) => state.specialitySubSpeciality || [],
  genericSpecialitySubSpeciality: (state) => state.genericSpecialitySubSpeciality || []
};

const actions = {
  initJobCandidatesStatusFetchOptions({ dispatch, getters, commit }) {
    let { getJobCandidateTabs } = getters;
    let appendFilterAction = [];
    dispatch("showLoader");
    commit("SET_FETCHING", true);
    appendFilterAction = [
      ...appendFilterAction,
      dispatch("fetchAllJobsFilterList"),
    ];
    if (!getJobCandidateTabs.length) {
      appendFilterAction = [
        ...appendFilterAction,
        dispatch("fetchJobCandidateTabs"),
      ];
    }
    return Promise.all(appendFilterAction).then((res) => {
      dispatch("hideLoader");
      commit("SET_FETCHING", false);
      return res;
    });
  },
  fetchAllJobsFilterList({ commit, getters }) {
    const {
      getChildOrgQuery,
      isCandidateSupplierFromAccessToken,
      getOrgIDFromAccessToken: org_id,
    } = getters;
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", true);
    let query =
      isCandidateSupplierFromAccessToken && org_id
        ? `job_allocation___allocated_organisation_id__in=${org_id}`
        : getChildOrgQuery;
    return jobBoard
      .fetchAllJobsFilterList({ query: query })
      .then((res) => {
        const { data } = res;
        commit("SET_ALL_JOBS_FILTER_LIST", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching all jobs filter list", err);
        return err;
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", false);
        return res;
      });
  },
  fetchJobTabFilterOptions({ commit }, { tab_id }) {
    if (!tab_id) {
      commit("RESET_TAB_FILTER_OPTIONS");
      commit("RESET_TAB_SUB_FILTER_OPTIONS");
      return;
    }
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", true);
    return tabFilters
      .getTabFilters({ tab_id })
      .then((res) => {
        const { data } = res;
        commit("SET_TAB_FILTER_OPTIONS", data);
      })
      .catch((err) => {
        console.log("Error while fetching tab filter list", err);
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", false);
        return res;
      });
  },
  fetchJobTabSubFilterOptions({ commit }, payload) {
    if (!payload?.filter_id) {
      commit("RESET_TAB_SUB_FILTER_OPTIONS");
      return;
    }
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", true);
    return tabFilters
      .getTabSubFilters(payload)
      .then((res) => {
        const { data } = res;
        commit("SET_TAB_SUB_FILTER_OPTIONS", data);
      })
      .catch((err) => {
        console.log("Error while fetching tab sub filter list", err);
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER", false);
        return res;
      });
  },
  fetchDisplayStatusSubStatus({ commit, getters, dispatch }) {
    const {
      getJobCandidateTabs,
      isJobSupplierFromAccessToken,
      isCandidateSupplierFromAccessToken,
    } = getters;
    const fields = {
      job: "job_id",
      tab: "tab_id",
      tabFilter: "filter_id",
      tabSubFilter: "sub_filter_id",
    };
    let payload = {};
    for (const [key, value] of Object.entries(router.currentRoute.query)) {
      if (fields[key])
        payload[fields[key]] = value;
    }
    const { tab_id, filter_id, sub_filter_id } = payload;
    commit("RESET_FILTER_DISPLAY_STATUS_SUB_STATUS");
    if (tab_id == "allTab") {
      let appendAction = [];
      getJobCandidateTabs.forEach((ele) => {
        appendAction = [
          ...appendAction,
          dispatch("fetchDisplayStatusSubStatusByTab", ele.tab_id),
        ];
      });
      return Promise.all(appendAction).then((res) => {
        return res;
      });
    }
    if (!(tab_id && filter_id)) return;
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS", true);
    let queryPayload = { user_action: 'display' };
    if (sub_filter_id)
      queryPayload = { ...queryPayload, sub_filter_id };
    if (filter_id)
      queryPayload = { ...queryPayload, filter_id__in: [filter_id] }
    if (tab_id)
      queryPayload = { ...queryPayload, tab_id }
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return tabFilters
      .getDisplayStatusSubStatus({ queryString })
      .then((res) => {
        const { data } = res;
        let status = data;
        if (isJobSupplierFromAccessToken)
          status = _(data)
            .filter(({ display_status }) => display_status != 75)
            .value();
        if (isCandidateSupplierFromAccessToken)
          status = _(data)
            .filter(({ display_status }) => display_status != 67)
            .value();
        commit("SET_FILTER_DISPLAY_STATUS_SUB_STATUS", status);
      })
      .catch((err) => {
        console.log("Error while fetching display status sub status", err);
      })
      .finally((res) => {
        return res;
      });
  },
  fetchDisplayStatusSubStatusByTab({ state, commit, dispatch }, tab_id) {
    const { isJobSupplierFromAccessToken, isCandidateSupplierFromAccessToken } =
      getters;
    return tabFilters
      .getDisplayStatusSubStatusByTab({ tab_id })
      .then((res) => {
        const { data } = res;
        let status = data;
        if (isJobSupplierFromAccessToken)
          status = _(data)
            .filter(({ display_status }) => display_status != 75)
            .value();
        if (isCandidateSupplierFromAccessToken)
          status = _(data)
            .filter(({ display_status }) => display_status != 67)
            .value();
        commit(
          "SET_FILTER_DISPLAY_STATUS_SUB_STATUS",
          state.displayStatusSubStatus.concat(status)
        );
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching display status by tab", err);
        return err;
      });
  },
  fetchJobCandidatesByStatusID({ commit, getters, dispatch }, payload) {
    const {
      displayStatusSubStatus,
      getJobCandidateStatusPagination,
      getJobCandidatesByStatusID,
      getChildOrgWithCustomerQuery,
      getJobCandidatesByStatusIDCount,
    } = getters;
    if (!displayStatusSubStatus?.length) {
      dispatch("resetJobCandidateStatus");
      commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
      commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
        noMoreCandidates: true,
      });
      return;
    }
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS", true);
    if (
      !payload.pagination &&
      (payload?.skip ? !getJobCandidatesByStatusIDCount : true)
    ) {
      commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
        skip: 0,
        noMoreCandidates: false,
      });
      dispatch("fetchJobCandidatesByStatusIDCount");
    }
    let skip = payload.pagination ? getJobCandidateStatusPagination.skip : 0;
    let limit = getJobCandidateStatusPagination.limit;

    const { skip: paginationSkip, limit: paginationLimit } = payload;
    if (paginationSkip && paginationLimit) {
      skip = paginationSkip;
      limit = paginationLimit;
    }

    let queryString = getChildOrgWithCustomerQuery;
    let query = router?.currentRoute?.query;
    if (query?.job) queryString += `&job_id__in=${query?.job}`;
    if (query?.tab == 1 && query?.tabFilter == 1)
      queryString += `&job_applied=true`;
    if (payload?.reference_type_id)
      queryString += `&reference_type_id=${payload?.reference_type_id}`;
    if (payload?.jobs___group_ids__overlap)
      queryString += `&group_ids__overlap=${payload?.jobs___group_ids__overlap}`;
    dispatch("showLoader");
    return tabFilters
      .fetchJobCandidatesByStatusID({
        skip: skip,
        limit: limit,
        queryString: queryString,
        formPayload: displayStatusSubStatus,
      })
      .then((res) => {
        const { data } = res;
        if (data.length < limit) {
          commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
            noMoreCandidates: true,
          });
        } else {
          commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
            skip: skip + limit,
            noMoreCandidates: false,
          });
        }
        if (payload.pagination) {
          commit(
            "SET_JOB_CANDIDATES_BY_STATUS_ID",
            getJobCandidatesByStatusID.concat(data)
          );
        } else {
          commit("SET_JOB_CANDIDATES_BY_STATUS_ID", data);
        }
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching display status sub status", err);
        return err;
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
        dispatch("hideLoader");
      });
  },
  fetchJobCandidatesByStatusIDCount({ commit, getters }) {
    const { getChildOrgWithCustomerQuery } = getters;
    const fields = {
      job: "job_id__in",
      tab: "tab_id",
      tabFilter: "filter_id",
      tabSubFilter: "sub_filter_id",
      reference_type_id: "reference_type_id",
      job_groups: "job_groups",
    };
    let payload = {};
    for (const [key, value] of Object.entries(router.currentRoute.query)) {
      if (fields[key]) payload[fields[key]] = value;
    }
    const { tab_id, filter_id, job_groups, ...rest } = payload;
    let queryPayload = { ...rest };
    if (tab_id)
      queryPayload = { ...queryPayload, tab_id };
    if (filter_id)
      queryPayload = { ...queryPayload, filter_id__in: filter_id }
    if (job_groups)
      queryPayload = { ...queryPayload, group_ids__overlap: `{${job_groups}}` };
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    queryString += `${queryString ? "&" : ""}${getChildOrgWithCustomerQuery}`;
    if (tab_id == "allTab") {
      return tabFilters
        .jobCandidateTabTotalCount(queryString)
        .then((res) => {
          const { data } = res;
          let totalCount = 0;
          data.forEach((ele) => {
            totalCount += ele.count;
          });
          commit("SET_JOB_CANDIDATES_BY_STATUS_ID_COUNT", totalCount);
          return res;
        })
        .catch((err) => {
          console.log("Error while fetching candidate status job count", err);
          return err;
        });
    }
    if (tab_id == 1 && filter_id == 1) queryString += `&job_applied=true`;
    return tabFilters
      .tabFilterCandidateCount({ queryString })
      .then((res) => {
        const { data } = res;
        commit("SET_JOB_CANDIDATES_BY_STATUS_ID_COUNT", data);
      })
      .catch((err) => {
        console.log("Error while fetching job candidate count", err);
      })
      .finally((res) => {
        return res;
      });
  },
  fetchJobApplications({ commit, getters, dispatch }, payload) {
    const {
      getJobCandidateStatusPagination,
      getJobCandidatesByStatusID,
      getJobCandidatesByStatusIDCount,
    } = getters;
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS", true);
    if (
      !payload.pagination &&
      (payload?.skip ? !getJobCandidatesByStatusIDCount : true)
    ) {
      commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
        skip: 0,
        noMoreCandidates: false,
      });
      dispatch("fetchJobApplicationsCount", payload);
    }
    let skip = payload.pagination ? getJobCandidateStatusPagination.skip : 0;
    let limit = getJobCandidateStatusPagination.limit;

    const { skip: paginationSkip, limit: paginationLimit, jobs___customer_uid, jobs___organisation_id, is_accepted__in, jobs___group_ids__overlap } = payload;
    if (paginationSkip && paginationLimit) {
      skip = paginationSkip;
      limit = paginationLimit;
    }
    let queryPayload = {
      is_current: true,
      all_rows: false,
      skip,
      limit,
      order_by: "-application_id",
      is_withdrawn: false,
    };
    let query = router?.currentRoute?.query;
    if (query?.job) queryPayload = { ...queryPayload, job_id__in: query?.job };
    if (jobs___customer_uid) queryPayload = { ...queryPayload, jobs___customer_uid };
    if (jobs___organisation_id) queryPayload = { ...queryPayload, jobs___organisation_id };
    if (is_accepted__in != undefined) queryPayload = { ...queryPayload, is_accepted__in };
    if (jobs___group_ids__overlap != undefined) queryPayload = { ...queryPayload, jobs___group_ids__overlap };
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    dispatch("showLoader");
    return job
      .fetchJobApplication(queryString)
      .then((res) => {
        const { data } = res;
        const data_mapped = data.map((v) => ({
          ...v,
          job_title: v?.job?.job_title,
          job_display_uid: v?.job?.display_uid,
        }));
        if (data.length < limit) {
          commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
            noMoreCandidates: true,
          });
        } else {
          commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
            skip: skip + limit,
            noMoreCandidates: false,
          });
        }
        if (payload.pagination) {
          commit(
            "SET_JOB_CANDIDATES_BY_STATUS_ID",
            getJobCandidatesByStatusID.concat(data_mapped)
          );
        } else {
          commit("SET_JOB_CANDIDATES_BY_STATUS_ID", data_mapped);
        }
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching job application", err);
        return err;
      })
      .finally((res) => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
        dispatch("hideLoader");
      });
  },
  fetchJobApplicationsCount({ commit, getters, dispatch }, payload = {}) {
    let queryPayload = {
      is_current: true,
      is_withdrawn: false,
    };
    const { jobs___customer_uid, jobs___organisation_id, is_accepted__in, jobs___group_ids__overlap } = payload;
    if (jobs___customer_uid) queryPayload = { ...queryPayload, jobs___customer_uid };
    if (jobs___organisation_id) queryPayload = { ...queryPayload, jobs___organisation_id };
    if (is_accepted__in != undefined) queryPayload = { ...queryPayload, is_accepted__in };
    if (jobs___group_ids__overlap != undefined) queryPayload = { ...queryPayload, jobs___group_ids__overlap };
    let query = router?.currentRoute?.query;
    if (query?.job) queryPayload = { ...queryPayload, job_id__in: query?.job };
    let queryString = getFilterQueryStringWithoutArray(queryPayload);
    return job
      .fetchJobApplicationCount(queryString)
      .then((res) => {
        const { data } = res;
        commit("SET_JOB_CANDIDATES_BY_STATUS_ID_COUNT", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching application job count", err);
        return err;
      });
  },
  resetJobCandidateStatus({ commit }) {
    commit("RESET_JOB_CANDIDATES_BY_STATUS_ID");
    commit("RESET_JOB_CANDIDATES_BY_STATUS_ID_COUNT");
  },
  fetchJobCandidatesAdvanced({ commit, dispatch, getters }, data = {}) {
    commit("SET_FETCHING_JOB_CANDIDATE_STATUS", true);
    commit("RESET_JOB_CANDIDATE_ADVANCED");
    const {
      displayStatusSubStatus,
      getChildOrgWithCustomerQuery,
      getJobCandidatesAdvancedCount,
      getCustomerId,
    } = getters;
    if (!displayStatusSubStatus?.length) {
      commit("RESET_JOB_CANDIDATE_ADVANCED_COUNT");
      commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
      return;
    }
    let queryPayload = { fetch_row_count: false, all_rows: false, customer_id: getCustomerId };
    const { skip, limit, job_id, pagination, payload, candidates___candidate_type_id__in, candidates___speciality_references, candidates___gender_id, candidates___current_license } = data;
    if (!pagination) {
      dispatch("fetchJobCandidatesAdvancedCount", data);
    }
    if (job_id) queryPayload = { ...queryPayload, job_id };
    if (limit) {
      const skip_data = skip || 0;
      queryPayload = { ...queryPayload, skip: skip_data, limit };
    }
    if (candidates___candidate_type_id__in)
      queryPayload = { ...queryPayload, candidates___candidate_type_id__in };
    if (candidates___speciality_references?.length)
      queryPayload = { ...queryPayload, candidates___speciality_references };
    if (candidates___gender_id)
      queryPayload = { ...queryPayload, candidates___gender_id };
    if (candidates___current_license != undefined)
      queryPayload = { ...queryPayload, candidates___current_license };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    const finalPayload = {
      status_sub_status: [...displayStatusSubStatus],
      ...payload,
    };
    return tabFilters
      .fetchJobCandidateForAdvanced({ queryString, payload: finalPayload })
      .then((res) => {
        const { data } = res;
        commit("SET_JOB_CANDIDATE_ADVANCED", data);
        return res;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
      });
  },
  fetchJobCandidatesAdvancedCount({ commit, dispatch, getters }, data = {}) {
    const {
      displayStatusSubStatus,
      getChildOrgWithCustomerQuery,
      getJobCandidatesAdvancedCount,
      getCustomerId
    } = getters;
    let queryPayload = { fetch_row_count: true, all_rows: true, customer_id: getCustomerId };
    const { skip, limit, job_id, payload, candidates___candidate_type_id__in, candidates___speciality_references, candidates___gender_id, candidates___current_license } = data;
    if (job_id) queryPayload = { ...queryPayload, job_id };
    if (limit) {
      const skip_data = skip || 0;
      queryPayload = { ...queryPayload, skip: skip_data, limit };
    }
    if (candidates___candidate_type_id__in)
      queryPayload = { ...queryPayload, candidates___candidate_type_id__in };
    if (candidates___speciality_references?.length)
      queryPayload = { ...queryPayload, candidates___speciality_references };
    if (candidates___gender_id)
      queryPayload = { ...queryPayload, candidates___gender_id };
    if (candidates___current_license != undefined)
      queryPayload = { ...queryPayload, candidates___current_license };
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    const finalPayload = {
      status_sub_status: [...displayStatusSubStatus],
      ...payload,
    };
    return tabFilters
      .fetchJobCandidateForAdvanced({ queryString, payload: finalPayload })
      .then((res) => {
        const { data } = res;
        commit("SET_JOB_CANDIDATE_ADVANCED_COUNT", data);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  initFetchAdvFilter: ({ getters, dispatch }) => {
    const { getGender, getCandidateType } = getters
    let appendAction = [];
    if (!getGender.length)
      appendAction = [...appendAction, dispatch("getGender")]
    if (!getCandidateType.length) {
      appendAction = [...appendAction, dispatch("fetchCandidateType")];
    }
    return Promise.all(appendAction)
  },
  getGenericSpecialitySubSpeciality: ({ commit }, payload) => {
    // let queryKey = 'speciality_references';
    let queryData = {};
    if (payload && payload.length)
      queryData = { speciality_references: payload }
    else {
      commit("SET_GENERIC_SPECIALITY_SUB_SPECIALITY", []);
      return;
    }
    const queryString = getFilterQueryStringWithoutArray(queryData);
    return filter.specialitySearch(queryString).then(res => {
      let { data } = res;
      const mappedData = data.map(({ speciality_reference, country_code, speciality, sub_speciality, sub_speciality_id, speciality_id, speciality_reference_group }) => ({
        label: `${country_code ? `${country_code} | ` : ''}${speciality} | ${sub_speciality}`,
        code: speciality_reference,
        speciality,
        sub_speciality,
        country_code,
        sub_speciality_id,
        speciality_id,
        id: speciality_reference,
        speciality_reference_group
      }));
      commit("SET_GENERIC_SPECIALITY_SUB_SPECIALITY", mappedData);
      return res;
    }).catch(err => {
      console.log("error in genericCountrySpecialitySubspeciality", err);
      return err;
    });
  }
}
const mutations = {
  ["SET_FETCHING_JOB_CANDIDATE_STATUS"](state, payload) {
    state.isFetchingJobCandidateSts = payload;
  },
  ["SET_FETCHING_JOB_CANDIDATE_STATUS_FILTER"](state, payload) {
    state.isFetchingJobCandidateStsFilter = payload;
  },
  ["SET_ALL_JOBS_FILTER_LIST"](state, payload) {
    state.getAllJobsFilterList = payload;
  },
  ["SET_TAB_FILTER_OPTIONS"](state, payload) {
    state.getTabFilterOptions = payload;
  },
  ["RESET_TAB_FILTER_OPTIONS"](state) {
    state.getTabFilterOptions = [];
  },
  ["SET_TAB_SUB_FILTER_OPTIONS"](state, payload) {
    state.getTabSubFilterOptions = payload;
  },
  ["RESET_TAB_SUB_FILTER_OPTIONS"](state) {
    state.getTabSubFilterOptions = [];
  },
  ["SET_FILTER_DISPLAY_STATUS_SUB_STATUS"](state, payload) {
    state.displayStatusSubStatus = payload;
  },
  ["RESET_FILTER_DISPLAY_STATUS_SUB_STATUS"](state) {
    state.displayStatusSubStatus = [];
  },
  ["SET_JOB_CANDIDATES_BY_STATUS_ID"](state, payload) {
    state.getJobCandidatesByStatusID = payload;
  },
  ["RESET_JOB_CANDIDATES_BY_STATUS_ID"](state) {
    state.getJobCandidatesByStatusID = [];
  },
  ["SET_JOB_CANDIDATES_BY_STATUS_ID_COUNT"](state, payload) {
    state.getJobCandidatesByStatusIDCount = payload;
  },
  ["RESET_JOB_CANDIDATES_BY_STATUS_ID_COUNT"](state) {
    state.getJobCandidatesByStatusIDCount = 0;
  },
  ["SET_JOB_CANDIDATE_STATUS_PAGINATION"](state, payload) {
    state.jobCandidateStatusPagination = {
      ...state.jobCandidateStatusPagination,
      ...payload,
    };
  },
  ["SET_JOB_CANDIDATE_ADVANCED"](state, payload) {
    state.getJobCandidatesAdvanced = payload;
  },
  ["RESET_JOB_CANDIDATE_ADVANCED"](state) {
    state.getJobCandidatesAdvanced = [];
  },
  ["SET_JOB_CANDIDATE_ADVANCED_COUNT"](state, payload) {
    state.getJobCandidatesAdvancedCount = payload;
  },
  ["RESET_JOB_CANDIDATE_ADVANCED_COUNT"](state) {
    state.getJobCandidatesAdvancedCount = 0;
  },
  ['SET_SPECIALITY_SUB_SPECIALITY'](state, payload) {
    state.specialitySubSpeciality = payload;
  },
  ['SET_GENERIC_SPECIALITY_SUB_SPECIALITY'](state, payload) {
    state.genericSpecialitySubSpeciality = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
