import jobAllocation from "@/services/jobAllocation";
import {getFilterQueryStringWithoutArray} from "@/helpers/helper";

const state = {
  allocatedJobs: [],
  allocatedJob: null,
  jobsListForAllocation: [],
  agencyOrganisations: [],
}


const getters = {
  getAllocatedJobs: state => state.allocatedJobs,
  getJobsForAllocation: state => state.jobsListForAllocation,
  getAgencyOrganisations: state => state.agencyOrganisations,
}

const actions = {
  initJobAllocationFetchOptions({ dispatch, getters }) {
    let { getAllocatedJobs, getJobsForAllocation, getAgencyOrganisations } = getters;
    let appendFilterAction = [];
    dispatch("showLoader");
    if (!getJobsForAllocation.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchJobsForAllocation")];
    }
    if (!getAllocatedJobs.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchAllocatedJobs")];
    }
    if (!getAgencyOrganisations.length) {
      appendFilterAction = [...appendFilterAction, dispatch("fetchAgencyOrganisations")];
    }
    return Promise.all(appendFilterAction).then((res) => {
      dispatch("hideLoader");
      return res;
    });
  },
  fetchJobsForAllocation({ commit, getters }) {
    return jobAllocation
      .getJobsForAllocation()
      .then((res) => {
        let { data } = res;
        const mappedData = data?.length
          ? data.map((val) => ({
            // label: `${val.job_id} | ${val.job_title}`,
            label: `${val.display_uid} | ${val.job_title}`,
            code: val.job_id,
          }))
          : []
        commit("SET_JOBS_FOR_ALLOCATION", mappedData);
      })
      .catch((err) => {
        console.log("Error while fetching jobs", err);
      });
  },
  fetchAgencyOrganisations: ({ commit, getters }) => {
    const { getCustomerId } = getters;
    const org_type_id = 13;
    let query = `organisation_type_id=${org_type_id}`
    return jobAllocation
      .getCustomerOrganizations({ customer_id: getCustomerId, query: query })
      .then(async (res) => {
        let { data } = res;
        const mappedData = data?.length
          ? data.map((val) => ({
            label: val.organisation.name,
            code: val.organisation_id,
          }))
          : []
        commit('SET_AGENCY_ORGANIZATIONS', mappedData);
        return res;
      })
      .catch((err) => {
        console.log('Error while fetching agency organizations', err);
        return err;
      });
  },
  fetchAllocatedJobs({ commit, getters, dispatch }) {
    const { getCustomerId } = getters;
    let queryPayload = { all_rows: true, order_by: "-created_on", jobs___customer_uid: getCustomerId };
    const query= getFilterQueryStringWithoutArray(queryPayload);
    return jobAllocation.getAllocatedJobs(getCustomerId, query)
      .then((res) => {
        let { data } = res
        commit("SET_ALLOCATED_JOBS", data)
      })
      .catch((err) => {
        console.error("Error in fetching Allocated Jobs", err);
        return err;
      });
  },
  allocateJob({ commit, getters, dispatch }, payload) {
    const { getCustomerId: customer_id } = getters
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Allocating Job...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return jobAllocation.allocateJob(customer_id, payload).then(res => {
      dispatch("fetchAllocatedJobs");
      commit("SET_TOAST_MESSAGE", "Job Allocated Successfully");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch((err) => {
        let msg = "Error while allocating Job";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  },
  reallocateJob({ commit, getters, dispatch }, payload) {
    const { getCustomerId: customer_id } = getters
    commit("SET_TOAST", true);
    commit("SET_TOAST_MESSAGE", "Saving...");
    commit("SET_TOAST_CLASS", "bg-info text-white");
    return jobAllocation.reallocateJob(customer_id, payload).then(res => {
      dispatch("fetchAllocatedJobs");
      commit("SET_TOAST_MESSAGE", "Updated Successfully");
      commit("SET_TOAST_CLASS", "bg-success text-white");
      commit("SET_TOAST_HIDE", 3000);
      return res;
    })
      .catch((err) => {
        let msg = "Error while allocating Job";
        commit("SET_UPLOADED", true);
        commit("SET_TOAST_MESSAGE", msg);
        commit("SET_TOAST_CLASS", "bg-danger text-white");
        commit("SET_TOAST_HIDE", 3000);
        return err;
      })
  },
  deleteAllocation({ dispatch, getters }, job_allocation_id) {
    const { getCustomerId: customer_id } = getters
    return jobAllocation.unallocateJob(customer_id, job_allocation_id).then(async res => {
      if (res.status == 200) {
        dispatch("showToast", { class: 'bg-success text-white', message: 'Allocation removed successfully' });
        dispatch("fetchAllocatedJobs");
      }
    })
      .catch(err => {
        if (err.response.status == 500) {
          dispatch("showToast", { class: 'bg-danger text-white', message: 'Error while removing allocation' });
        }
        console.log("Error while removing job Allocation", err);
        return err;
      });
  },

}

const mutations = {
  ["SET_ALLOCATED_JOBS"](state, payload) {
    state.allocatedJobs = payload
  },
  ["SET_AGENCY_ORGANIZATIONS"](state, payload) {
    state.agencyOrganisations = payload
  },
  ["SET_JOBS_FOR_ALLOCATION"](state, payload) {
    state.jobsListForAllocation = payload
  }
}

export default { state, getters, actions, mutations };
